body {
    text-transform: uppercase;
}

.css-bq11r7{
    padding-top: 10% !important;
    padding-bottom: 10%;
    padding-left: 5%;
    padding-right: 5%;
}

.css-1h15ts-MuiPaper-root-MuiCard-root {
    padding-top: 10%;
    padding-bottom: 10%;
}

.css-xn6ruc {
    display: flex;
}

.css-17li347-MuiDivider-root {
    background-color: #fff;
}

.css-15m7mkq {
    background: linear-gradient(195deg, #fe914d, #f07117) !important;
}

.css-w51ld2 {
    background: #000000ed !important;
}

.css-1x3v3vy {
    background: linear-gradient(195deg, #fe914d, #f07117) !important;
}

.left_t {
    text-align: left;
    width: 50%;
}

.right_t {
    text-align: left;
    font-size: larger;
    width: 50%;
}

.bton_oba {
    width: 100px;
    height: 50px;
    border-radius: 10%;
    color: #fff;
    background-color: #fe914d;
    border-color: #fe914d;
    border-width: inherit;
    position: relative;
    text-transform: uppercase;
    font-weight: bold;
}

.Imgg {
    width: 100%;
    border-radius: 2%;
}

.wtr {
    color: #fff !important;
}